define("ember-model-validator/messages/sr-cyrl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    presenceMessage: 'не може остати празно/а',
    absenceMessage: 'мора бити празно/а',
    inclusionMessage: 'није на листи',
    exclusionMessage: 'резервисано/на',
    // Numericality
    numericalityMessage: 'треба да је број',
    numericalityOnlyIntegerMessage: 'треба да буде цео број',
    numericalityGreaterThanMessage: 'треба да је веће/а од {count}',
    numericalityGreaterThanOrEqualToMessage: 'треба да је веће/а или једнако/а од/са {count}',
    numericalityEqualToMessage: 'треба да буде једнако/а са {count}',
    numericalityLessThanMessage: 'треба да буде мање/а од {count}',
    numericalityLessThanOrEqualToMessage: 'треба да буде мање/а или једнако/а од/са {count}',
    numericalityOddMessage: 'треба да буде непарно/а',
    numericalityEvenMessage: 'треба да буде парно/а',
    mailMessage: 'треба да буде важећа емаил адреса',
    formatMessage: 'садржи грешку',
    colorMessage: 'мора бити важец́и ЦСС хеx код боје',
    subdomainMessage: 'мора да буде важећи поддомен',
    acceptanceMessage: 'мора бити прихваћено/а',
    zipCodeMessage: 'није важец́и поштански број',
    URLMessage: 'мора да буде важећи урл',
    wrongLengthMessage: 'погрешне дужине (требало/ла би бити {count} карактера)',
    tooShortMessage: 'прекратко/а (минимум је {count} карактера)',
    tooLongMessage: 'предуго/а (максимум је {count} карактера)',
    mustContainNumberMessage: 'мора да садржи број',
    mustContainSpecialMessage: 'мора да садржи један од ових специјалних знакова: {characters}',
    mustContainLowerMessage: 'мора да садржи мала слова',
    mustContainCapitalMessage: 'мора да садржи велико слово',
    customValidationMessage: 'садржи грешку',
    matchMessage: 'треба да буде једнако/а са {match}',
    dateMessage: 'погрешног формата датума',
    dateBeforeMessage: 'мора бити пре {date}',
    dateAfterMessage: 'мора бити после {date}'
  };
});