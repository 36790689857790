define("ember-radio-button/components/radio-button-input", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/runloop", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _runloop, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <input ...attributes type="radio" checked={{@checked}} aria-checked={{this.checkedStr}} value={{@value}} {{on "change" this.change}} />
  
  */
  {
    "id": "QZTYXx7c",
    "block": "[[[11,\"input\"],[17,1],[24,4,\"radio\"],[16,\"checked\",[30,2]],[16,\"aria-checked\",[30,0,[\"checkedStr\"]]],[16,2,[30,3]],[4,[38,0],[\"change\",[30,0,[\"change\"]]],null],[12],[13],[1,\"\\n\"]],[\"&attrs\",\"@checked\",\"@value\"],false,[\"on\"]]",
    "moduleName": "ember-radio-button/components/radio-button-input.hbs",
    "isStrictMode": false
  });
  let RadioButtonInputComponent = _exports.default = (_class = class RadioButtonInputComponent extends _component2.default {
    get checkedStr() {
      const checked = this.args.checked;
      if (typeof checked === 'boolean') {
        return checked.toString();
      }
      return null;
    }
    change() {
      if (this.args.groupValue !== this.args.value) {
        // this.set('groupValue', value);
        (0, _runloop.once)(this.args, 'changed', this.args.value);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RadioButtonInputComponent);
});