define("ember-model-validator/messages/uk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    presenceMessage: 'не має бути пустим',
    absenceMessage: 'має бути пустим',
    inclusionMessage: 'не має в списку',
    exclusionMessage: 'зарезервоване',
    // Numericality
    numericalityMessage: 'не число',
    numericalityOnlyIntegerMessage: 'має бути цілим числом',
    numericalityGreaterThanMessage: 'має бути більше {count}',
    numericalityGreaterThanOrEqualToMessage: 'має бути більшим або рівним {count}',
    numericalityEqualToMessage: 'має бути рівним {count}',
    numericalityLessThanMessage: 'має бути меншим {count}',
    numericalityLessThanOrEqualToMessage: 'має бути меншим або рівним {count}',
    numericalityOddMessage: 'має бути непарним',
    numericalityEvenMessage: 'має бути парним',
    mailMessage: 'невалідна електропошта',
    formatMessage: 'невалідне',
    colorMessage: 'має бути валідний CSS шестнадцятирічний код кольору',
    subdomainMessage: 'має юути валідний субдомен',
    acceptanceMessage: 'має бути акцептоване',
    zipCodeMessage: 'не валідний індекс',
    URLMessage: 'має бути валідним посиланням',
    wrongLengthMessage: 'невірна довжина (має бути {count} символів)',
    tooShortMessage: 'закоротке (манімум {count} символів)',
    tooLongMessage: 'задовге (максимум {count} символів)',
    mustContainNumberMessage: 'має містити цифру',
    mustContainSpecialMessage: 'має містити один із символів: {characters}',
    mustContainLowerMessage: 'має містити маленькі літери',
    mustContainCapitalMessage: 'має містити великі літери',
    customValidationMessage: 'невалідне',
    matchMessage: 'має відповідати {match}',
    dateMessage: 'невалідна дата',
    dateBeforeMessage: 'має бути до {date}',
    dateAfterMessage: 'має бути після {date}'
  };
});