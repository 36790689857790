define("ember-model-validator/decorators/model-validator", ["exports", "@ember/object", "ember-model-validator/decorators/core-validator"], function (_exports, _object, _coreValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function modelValidator(constructor) {
    var _class;
    let ModelValidator = (0, _coreValidator.default)(_class = class ModelValidator extends constructor {
      clearErrors() {
        let errors = this.errors;
        errors.clear();
        (0, _object.set)(this, 'validationErrors', {});
        (0, _object.set)(this, 'isValidNow', true);
      }
      pushErrors(errors) {
        // This is a hack to support Ember Data 3.28
        if (this._internalModel?.transitionTo) {
          const stateToTransition = this.isNew ? 'created.uncommitted' : 'updated.uncommitted';
          this._internalModel.transitionTo(stateToTransition);
          const recordModel = this.adapterDidInvalidate ? this : this._internalModel;
          this.store.recordWasInvalid(recordModel, errors, 'error');
        } else {
          const modelErrors = this.errors;
          Object.keys(errors).forEach(function (error) {
            modelErrors.add(error, errors[error]);
          });
        }
      }
    }) || _class;
    return ModelValidator;
  }
  var _default = _exports.default = modelValidator;
});